<template>
  <div class="home">
    <div class="form person-info">
      <label>Your Info</label>
      <div class="input-wrapper">
        <label>Name</label>
        <input type="text" name="" value="" v-model="name">
      </div>
      <div class="input-wrapper">
        <label>Email</label>
        <input type="text" name="" value="" v-model="email">
      </div>
      <div class="input-wrapper">
        <label>Phone</label>
        <input type="text" name="" value="" v-model="phone">
      </div>
      <div class="input-wrapper">
        <label>Company</label>
        <input type="text" name="" value="" v-model="company">
      </div>

    </div>

    <div class="form" v-if="this.STATE === 'PHOTOS_AND_DESC'">
      <label>Pictures & Descriptions</label>
      <div class="photo-upload-wrapper" v-for="item in PHOTOS_AND_DESC.photos" :key="item.path">
        <img class="photo-upload-thumbnail" alt="" :src="item.path" v-if="item">
        <div class="photo-upload-details-wrapper">
          <span class="filename"><strong>Filename: </strong>{{ item.name }}</span>
          <div class="input-wrapper">
            <label for="">Description: </label>
            <textarea v-model="item.description"></textarea>
          </div>
        </div>
        <span class="remove-photo-button" @click="removePhoto(item)">
          <i class="fas fa-trash"></i>
        </span>

      </div>
      <input class="custom-file-input" type="file" @change="fileChanged" ref="photo_input">
    </div>


    <div class="button-wrapper submit-wrapper">
      <div class="button" @click="upload()">Submit</div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios';

export default {

  name: 'Home',
  data() {
    return {
     name: "",
     email: "",
     phone: "",
     company: "",
     STATE: "PHOTOS_AND_DESC",
     PHOTOS_AND_DESC: {
       description: "",
       photos: [],
     }
    }
  },
  methods: {
    fileChanged(e) {
      let toAdd = e.target.files[0];
      toAdd.path = URL.createObjectURL(toAdd);
      this.PHOTOS_AND_DESC.photos.push(e.target.files[0]);
      console.log(e.target.files[0]);

      this.$refs.photo_input.value = null;

    },
    removePhoto(p) {
      this.PHOTOS_AND_DESC.photos = this.PHOTOS_AND_DESC.photos.filter(el => el !== p);
    },
    async upload() {
      try {
        const formData = new FormData();

        formData.append("name", this.name);
        formData.append("email", this.email);
        formData.append("phone", this.phone);
        formData.append("company", this.company);


        for (let p of this.PHOTOS_AND_DESC.photos) {
          formData.append("description", p.description);
        }

        for (let p of this.PHOTOS_AND_DESC.photos) {
          formData.append("photo", p, p.name);
        }

        let r1 = await axios.post("/api/photos", formData);
        console.log(r1);
        let to_r2 = {
          person_info: r1.data.person_info,
          photo_urls: r1.data.photo_urls,
        };

        console.log(to_r2);

        let r2 = await axios.post("/api/rfq", to_r2);

        console.log(r2);
      } catch (e) {
        console.log(e);
      }
    }
  },
}
</script>

<style scoped>
  .image h2 {
    font-style: italic;
  }

  /* Masonry */
  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  .image-gallery {
    column-gap: 1.5em;
  }

  .image {
    margin: 0 0 1.5em;
    display: inline-block;
    width: 100%;
    border: 1px solid #ebebeb;
    border-radius: 5px;
    padding: 0 5px;
    box-shadow: 0 0 5px 1px #ebebeb;
  }

  .image img {
    width: 100%;
  }

  .image .desc {
    font-size: 0.75rem;
    width: 100%;
    text-align: center;
  }

  /* Masonry on large screens */
  @media only screen and (min-width: 1024px) {
    .image-gallery {
      column-count: 4;
    }
  }

  /* Masonry on medium-sized screens */
  @media only screen and (max-width: 1023px) and (min-width: 768px) {
    .image-gallery {
      column-count: 3;
    }
  }

  /* Masonry on small screens */
  @media only screen and (max-width: 767px) and (min-width: 540px) {
    .image-gallery {
      column-count: 2;
    }
  }
</style>
